import request from '@/router/axios';
import { Form } from 'element-ui';
import {userUrl} from '@/config/env';
export const list = (current,size,nameOrCode) => {
    return request({
        url : userUrl + "corporation/selectPage",
        method: "post",
        data: {
            pageNo : current,
            pageSize : size,
            nameOrCode
        }
    })
}
//计量单位下拉框初始化
export const unitOptionsInit = () => {
    return request({
        url : userUrl + "unit/selectShowUnit",
        method: "get"
    })
}
//规则下拉框初始化
export const ruleOptionsInit = () => {
    return request({
        url : userUrl + "codeRule/selectAllByCorpId",
        method: "get"
    })
}
//获取本公司系统配置
export const getSettings = () => {
    return request({
        url : userUrl + "setting/selectData",
        method: "get"
    })
}
//存货类型下拉框初始化
export const inventoryTypeInit = () => {
    return request({
        url : userUrl + "dict/selectDictByCategoryId",
        method: "get",
        params: {
            categoryId: "inventoryType"
        }
    })
}
//保存
export const saveData = (settings) => {
    return request({
        url : userUrl + "setting/saveSetting",
        method: "post",
        data: {
            settings
        }
    })
}
