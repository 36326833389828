var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "basic-container",
    [
      _c(
        "el-form",
        { ref: "form", attrs: { "label-width": "200px" } },
        [
          _c(
            "el-collapse",
            {
              model: {
                value: _vm.activeNames,
                callback: function($$v) {
                  _vm.activeNames = $$v
                },
                expression: "activeNames"
              }
            },
            [
              _c(
                "el-collapse-item",
                { attrs: { title: "系统默认基础配置", name: "1" } },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "系统名称:",
                                "label-width": "150px"
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入名称" },
                                model: {
                                  value: _vm.settings[6].value,
                                  callback: function($$v) {
                                    _vm.$set(_vm.settings[6], "value", $$v)
                                  },
                                  expression: "settings[6].value"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "默认时区:",
                                "label-width": "150px"
                              }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    filterable: "",
                                    placeholder: "请选择",
                                    clearable: ""
                                  },
                                  model: {
                                    value: _vm.settings[2].value,
                                    callback: function($$v) {
                                      _vm.$set(_vm.settings[2], "value", $$v)
                                    },
                                    expression: "settings[2].value"
                                  }
                                },
                                _vm._l(_vm.timeZoneOptions, function(
                                  item,
                                  index
                                ) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: { label: item, value: item }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-collapse-item",
                { attrs: { title: "系统功能配置", name: "2" } },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "是否接受单锭数据:",
                                "label-width": "150px"
                              }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    filterable: "",
                                    placeholder: "请选择",
                                    clearable: ""
                                  },
                                  model: {
                                    value: _vm.settings[1].value,
                                    callback: function($$v) {
                                      _vm.$set(_vm.settings[1], "value", $$v)
                                    },
                                    expression: "settings[1].value"
                                  }
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "是", value: "1" }
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "否", value: "2" }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "是否接受物联网数据:",
                                "label-width": "150px"
                              }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    filterable: "",
                                    placeholder: "请选择",
                                    clearable: ""
                                  },
                                  model: {
                                    value: _vm.settings[7].value,
                                    callback: function($$v) {
                                      _vm.$set(_vm.settings[7], "value", $$v)
                                    },
                                    expression: "settings[7].value"
                                  }
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "是", value: "1" }
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "否", value: "2" }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "时间采集方式:",
                                "label-width": "150px"
                              }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    filterable: "",
                                    placeholder: "请选择",
                                    clearable: ""
                                  },
                                  model: {
                                    value: _vm.settings[8].value,
                                    callback: function($$v) {
                                      _vm.$set(_vm.settings[8], "value", $$v)
                                    },
                                    expression: "settings[8].value"
                                  }
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "物联采集时间", value: "1" }
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "服务器时间", value: "2" }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "班组采集方式:",
                                "label-width": "150px"
                              }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    filterable: "",
                                    placeholder: "请选择",
                                    clearable: ""
                                  },
                                  on: { change: _vm.changeGroup },
                                  model: {
                                    value: _vm.settings[9].value,
                                    callback: function($$v) {
                                      _vm.$set(_vm.settings[9], "value", $$v)
                                    },
                                    expression: "settings[9].value"
                                  }
                                },
                                [
                                  _c("el-option", {
                                    attrs: {
                                      label: "物联设备传输班组",
                                      value: "1"
                                    }
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "系统排班", value: "2" }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "品种采集方式:",
                                "label-width": "150px"
                              }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    filterable: "",
                                    placeholder: "请选择",
                                    clearable: ""
                                  },
                                  model: {
                                    value: _vm.settings[10].value,
                                    callback: function($$v) {
                                      _vm.$set(_vm.settings[10], "value", $$v)
                                    },
                                    expression: "settings[10].value"
                                  }
                                },
                                [
                                  _c("el-option", {
                                    attrs: {
                                      label: "物联设备传输品种",
                                      value: "1"
                                    }
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "系统品种", value: "2" }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "默认存货类型:",
                                "label-width": "150px"
                              }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    filterable: "",
                                    placeholder: "请选择",
                                    clearable: ""
                                  },
                                  model: {
                                    value: _vm.settings[11].value,
                                    callback: function($$v) {
                                      _vm.$set(_vm.settings[11], "value", $$v)
                                    },
                                    expression: "settings[11].value"
                                  }
                                },
                                _vm._l(_vm.inventoryTypeOptions, function(
                                  item,
                                  index
                                ) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: { label: item.name, value: item.id }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "落纱状态采集方式:",
                                "label-width": "150px"
                              }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    filterable: "",
                                    placeholder: "请选择",
                                    clearable: ""
                                  },
                                  model: {
                                    value: _vm.settings[12].value,
                                    callback: function($$v) {
                                      _vm.$set(_vm.settings[12], "value", $$v)
                                    },
                                    expression: "settings[12].value"
                                  }
                                },
                                [
                                  _c("el-option", {
                                    attrs: {
                                      label: "物联设备传输落纱状态",
                                      value: "1"
                                    }
                                  }),
                                  _c("el-option", {
                                    attrs: {
                                      label: "系统根据已纺长度判断",
                                      value: "2"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-collapse-item",
                { attrs: { title: "系统自动程序默认设置", name: "3" } },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "标准计量单位:",
                                "label-width": "150px"
                              }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    filterable: "",
                                    placeholder: "请选择",
                                    clearable: ""
                                  },
                                  model: {
                                    value: _vm.settings[0].value,
                                    callback: function($$v) {
                                      _vm.$set(_vm.settings[0], "value", $$v)
                                    },
                                    expression: "settings[0].value"
                                  }
                                },
                                _vm._l(_vm.unitOptions, function(item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.label,
                                      value: item.value
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "生成故障单号规则:",
                                "label-width": "150px"
                              }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    filterable: "",
                                    placeholder: "请选择",
                                    clearable: ""
                                  },
                                  model: {
                                    value: _vm.settings[3].value,
                                    callback: function($$v) {
                                      _vm.$set(_vm.settings[3], "value", $$v)
                                    },
                                    expression: "settings[3].value"
                                  }
                                },
                                _vm._l(_vm.ruleOptions, function(item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: {
                                      label: item.name,
                                      value: item.code
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "生成批号规则:",
                                "label-width": "150px"
                              }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    filterable: "",
                                    placeholder: "请选择",
                                    clearable: ""
                                  },
                                  model: {
                                    value: _vm.settings[4].value,
                                    callback: function($$v) {
                                      _vm.$set(_vm.settings[4], "value", $$v)
                                    },
                                    expression: "settings[4].value"
                                  }
                                },
                                _vm._l(_vm.ruleOptions, function(item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: {
                                      label: item.name,
                                      value: item.code
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "生成工艺单号规则:",
                                "label-width": "150px"
                              }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    filterable: "",
                                    placeholder: "请选择",
                                    clearable: ""
                                  },
                                  model: {
                                    value: _vm.settings[5].value,
                                    callback: function($$v) {
                                      _vm.$set(_vm.settings[5], "value", $$v)
                                    },
                                    expression: "settings[5].value"
                                  }
                                },
                                _vm._l(_vm.ruleOptions, function(item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: {
                                      label: item.name,
                                      value: item.code
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "生成工单单号规则:",
                                "label-width": "150px"
                              }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    filterable: "",
                                    placeholder: "请选择",
                                    clearable: ""
                                  },
                                  model: {
                                    value: _vm.settings[17].value,
                                    callback: function($$v) {
                                      _vm.$set(_vm.settings[17], "value", $$v)
                                    },
                                    expression: "settings[17].value"
                                  }
                                },
                                _vm._l(_vm.ruleOptions, function(item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: {
                                      label: item.name,
                                      value: item.code
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-collapse-item",
                { attrs: { title: "系统物料价格采集设置", name: "4" } },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "物料价格采集周期:",
                                "label-width": "150px"
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "block" },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: {
                                        filterable: "",
                                        placeholder: "请选择",
                                        clearable: ""
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.cycleChange(
                                            _vm.settings[13].value
                                          )
                                        }
                                      },
                                      model: {
                                        value: _vm.settings[13].value,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.settings[13],
                                            "value",
                                            $$v
                                          )
                                        },
                                        expression: "settings[13].value"
                                      }
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: { label: "年", value: "years" }
                                      }),
                                      _c("el-option", {
                                        attrs: { label: "月", value: "month" }
                                      }),
                                      _c("el-option", {
                                        attrs: { label: "周", value: "weeks" }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _vm.cycleType
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: _vm.dynamicCycleCountName,
                                    "label-width": "150px"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "block" },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          type: "number",
                                          filterable: "",
                                          placeholder: "请输入",
                                          clearable: ""
                                        },
                                        model: {
                                          value: _vm.settings[14].value,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.settings[14],
                                              "value",
                                              $$v
                                            )
                                          },
                                          expression: "settings[14].value"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _vm.startTimeType
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "起始结算时间:",
                                    "label-width": "150px"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "block" },
                                    [
                                      _c("el-date-picker", {
                                        staticStyle: { width: "100%" },
                                        attrs: {
                                          filterable: "",
                                          "value-format": "yyyy-MM-dd",
                                          type: "date",
                                          clearable: "",
                                          placeholder: "选择日期"
                                        },
                                        model: {
                                          value: _vm.settings[15].value,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.settings[15],
                                              "value",
                                              $$v
                                            )
                                          },
                                          expression: "settings[15].value"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-collapse-item",
                { attrs: { title: "系统会计年度设置", name: "5" } },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "年度起始时间:",
                                "label-width": "150px"
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "block" },
                                [
                                  _c("el-date-picker", {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      filterable: "",
                                      "value-format": "yyyy-MM-dd",
                                      type: "date",
                                      clearable: "",
                                      placeholder: "选择日期"
                                    },
                                    model: {
                                      value: _vm.settings[16].value,
                                      callback: function($$v) {
                                        _vm.$set(_vm.settings[16], "value", $$v)
                                      },
                                      expression: "settings[16].value"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.save } },
            [_vm._v(_vm._s(_vm.$t("Yes")))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }