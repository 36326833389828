<template>
    <basic-container>
        <el-form ref="form" label-width="200px">
            <el-collapse v-model="activeNames">
                <el-collapse-item title="系统默认基础配置" name="1">
                    <el-row>
                        <el-col :span="6">
                            <el-form-item label="系统名称:" label-width="150px">
                                <el-input v-model="settings[6].value" placeholder="请输入名称"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="默认时区:" label-width="150px">
                                <el-select v-model="settings[2].value" filterable placeholder="请选择" clearable>
                                    <el-option
                                            v-for="(item,index) in timeZoneOptions"
                                            :key="index"
                                            :label="item"
                                            :value="item">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-collapse-item>
                <el-collapse-item title="系统功能配置" name="2">
                    <el-row>
                        <el-col :span="6">
                            <el-form-item label="是否接受单锭数据:" label-width="150px">
                                <el-select v-model="settings[1].value" filterable placeholder="请选择" clearable>
                                    <el-option label="是" value=1></el-option>
                                    <el-option label="否" value=2></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="是否接受物联网数据:" label-width="150px">
                                <el-select v-model="settings[7].value" filterable placeholder="请选择" clearable>
                                    <el-option label="是" value=1></el-option>
                                    <el-option label="否" value=2></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="时间采集方式:" label-width="150px">
                                <el-select v-model="settings[8].value" filterable placeholder="请选择" clearable>
                                    <el-option label="物联采集时间" value=1></el-option>
                                    <el-option label="服务器时间" value=2></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="班组采集方式:" label-width="150px">
                                <el-select v-model="settings[9].value" filterable placeholder="请选择" clearable
                                           @change="changeGroup">
                                    <el-option label="物联设备传输班组" value=1></el-option>
                                    <el-option label="系统排班" value=2></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="品种采集方式:" label-width="150px">
                                <el-select v-model="settings[10].value" filterable placeholder="请选择" clearable>
                                    <el-option label="物联设备传输品种" value=1></el-option>
                                    <el-option label="系统品种" value=2></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="默认存货类型:" label-width="150px">
                                <el-select v-model="settings[11].value" filterable placeholder="请选择" clearable>
                                    <el-option
                                            v-for="(item,index) in inventoryTypeOptions"
                                            :key="index"
                                            :label="item.name"
                                            :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="落纱状态采集方式:" label-width="150px">
                                <el-select v-model="settings[12].value" filterable placeholder="请选择" clearable>
                                    <el-option label="物联设备传输落纱状态" value=1></el-option>
                                    <el-option label="系统根据已纺长度判断" value=2></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-collapse-item>
                <el-collapse-item title="系统自动程序默认设置" name="3">
                    <el-row>
                        <el-col :span="6">
                            <el-form-item label="标准计量单位:" label-width="150px">
                                <el-select v-model="settings[0].value" filterable placeholder="请选择" clearable>
                                    <el-option
                                            v-for="(item,index) in unitOptions"
                                            :key="index"
                                            :label="item.label"
                                            :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="6">
                            <el-form-item label="生成故障单号规则:" label-width="150px">
                                <el-select v-model="settings[3].value" filterable placeholder="请选择" clearable>
                                    <el-option
                                            v-for="item in ruleOptions"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.code">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="生成批号规则:" label-width="150px">
                                <el-select v-model="settings[4].value" filterable placeholder="请选择" clearable>
                                    <el-option
                                            v-for="item in ruleOptions"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.code">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="生成工艺单号规则:" label-width="150px">
                                <el-select v-model="settings[5].value" filterable placeholder="请选择" clearable>
                                    <el-option
                                            v-for="item in ruleOptions"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.code">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="生成工单单号规则:" label-width="150px">
                                <el-select v-model="settings[17].value" filterable placeholder="请选择" clearable>
                                    <el-option
                                            v-for="item in ruleOptions"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.code">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-collapse-item>
                <el-collapse-item title="系统物料价格采集设置" name="4">
                    <el-row>
                        <el-col :span="6">
                            <el-form-item label="物料价格采集周期:" label-width="150px">
                                <div class="block">
                                    <el-select v-model="settings[13].value"  @change="cycleChange(settings[13].value)" filterable placeholder="请选择" clearable>
                                        <el-option label="年" value=years></el-option>
                                        <el-option label="月" value=month></el-option>
                                        <el-option label="周" value=weeks></el-option>
                                    </el-select>
                                </div>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item :label=dynamicCycleCountName label-width="150px" v-if=cycleType>
                                <div class="block">
                                    <el-input
                                            type="number"
                                            v-model="settings[14].value" filterable placeholder="请输入" clearable>
                                    </el-input>
                                </div>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="起始结算时间:" label-width="150px" v-if=startTimeType>
                                <div class="block">
                                    <el-date-picker v-model="settings[15].value"
                                                    filterable
                                                    value-format="yyyy-MM-dd"
                                                    type="date"
                                                    clearable
                                                    placeholder="选择日期" style="width:100%;">
                                    </el-date-picker>
                                </div>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-collapse-item>
                <el-collapse-item title="系统会计年度设置" name="5">
                    <el-row>
                        <el-col :span="6">
                            <el-form-item label="年度起始时间:" label-width="150px">
                                <div class="block">
                                    <el-date-picker v-model="settings[16].value"
                                                    filterable
                                                    value-format="yyyy-MM-dd"
                                                    type="date"
                                                    clearable
                                                    placeholder="选择日期" style="width:100%;">
                                    </el-date-picker>
                                </div>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-collapse-item>
            </el-collapse>
            <el-button type="primary" @click="save">{{$t("Yes")}}</el-button>
        </el-form>
    </basic-container>
</template>
<script>
    import {unitOptionsInit, ruleOptionsInit, saveData, getSettings, inventoryTypeInit} from "@/api/system"

    export default {
        data() {
            return {
                dynamicCycleCountName:'',
                cycleType: false,
                startTimeType: false,
                activeNames: ['1', '2', '3', '4', '5'],
                settings: [{
                    code: 'unit',
                    name: '标准计量单位',
                    value: null
                }, {
                    code: 'singleSystem',
                    name: '是否为单锭系统',
                    value: null
                }, {
                    code: 'timeZone',
                    name: '默认时区',
                    value: null
                }, {
                    code: 'faultRule',
                    name: '生成故障单号规则',
                    value: null
                }, {
                    code: 'batchRule',
                    name: '生成批号规则',
                    value: null
                }, {
                    code: 'specSheetRule',
                    name: '生成工艺单号规则',
                    value: null
                }, {
                    code: 'systemName',
                    name: '系统名称',
                    value: null
                }, {
                    code: 'acceptIotData',
                    name: '是否接受物联网数据',
                    value: null
                }, {
                    code: 'defultTime',
                    name: '时间信息',
                    value: null
                }, {
                    code: 'defultGroup',
                    name: '班组信息',
                    value: null
                }, {
                    code: 'defultProduct',
                    name: '品种信息',
                    value: null
                }, {
                    code: 'inventoryType',
                    name: '默认存货类型',
                    value: null
                }, {
                    code: 'defultDoffing',
                    name: '默认落纱状态采集方式',
                    value: null
                }, {
                    code: 'priceCycle',
                    name: '价格采集周期',
                    value: null
                }, {
                    code: 'cycleCount',
                    name: '周期数',
                    value: null
                }, {
                    code: 'startTime',
                    name: '起始结算时间',
                    value: null
                }, {
                    code: 'fiscalStartTime',
                    name: '年度起始时间',
                    value: null
                },{
                    code: 'issueRule',
                    name: '生成工单单号规则',
                    value: null
                }],
                ruleOptions: [],
                unitOptions: [],
                inventoryTypeOptions: [],
                timeZoneOptions: [
                    '(GMT-11:00)阿皮亚',
                    '(GMT-11:00)纽埃',
                    '(GMT-11:00)帕果帕果',
                    '(GMT-11:00)中途岛',
                    '(GMT-10:00)法考福',
                    '(GMT-10:00)拉罗汤加岛',
                    '(GMT-10:00)塔希提岛',
                    '(GMT-10:00)夏威夷时间',
                    '(GMT-10:00)约翰斯顿',
                    '(GMT-09:30)马克萨斯',
                    '(GMT-09:00)阿拉斯加时间',
                    '(GMT-09:00)甘比尔群岛',
                    '(GMT-08:00)皮特凯恩',
                    '(GMT-08:00)太平洋时间',
                    '(GMT-08:00)太平洋时间-怀特霍斯',
                    '(GMT-08:00)太平洋时间-提华纳',
                    '(GMT-08:00)太平洋时间-温哥华',
                    '(GMT-07:00)山地区时间-埃德蒙顿',
                    '(GMT-07:00)山地区时间-埃莫西约',
                    '(GMT-07:00)山地区时间-道生克里克',
                    '(GMT-07:00)山地区时间-奇瓦瓦、马萨特兰',
                    '(GMT-07:00)山地区时间-耶洛奈夫',
                    '(GMT-07:00)山地时间-亚利桑那州',
                    '(GMT-07:00)山区地时间',
                    '(GMT-06:00)伯利兹',
                    '(GMT-06:00)复活节岛',
                    '(GMT-06:00)哥斯达黎加',
                    '(GMT-06:00)加拉帕哥斯群岛',
                    '(GMT-06:00)马那瓜',
                    '(GMT-06:00)萨尔瓦多',
                    '(GMT-06:00)危地马拉',
                    '(GMT-06:00)中部央标准时间',
                    '(GMT-06:00)中部央标准时间',
                    '(GMT-06:00)中央标准部时间-墨西哥城',
                    '(GMT-06:00)中央标准时间-里贾纳',
                    '(GMT-06:00)中央标准时间-温尼伯湖',
                    '(GMT-05:00)巴拿马',
                    '(GMT-05:00)波哥大',
                    '(GMT-05:00)大特克斯岛',
                    '(GMT-05:00)东部时间',
                    '(GMT-05:00)东部时间-多伦多',
                    '(GMT-05:00)东部时间-蒙特利尔',
                    '(GMT-05:00)东部时间-伊魁特',
                    '(GMT-05:00)瓜亚基尔',
                    '(GMT-05:00)哈瓦那',
                    '(GMT-05:00)开曼群岛',
                    '(GMT-05:00)里奥布郎库',
                    '(GMT-05:00)利马',
                    '(GMT-05:00)拿骚',
                    '(GMT-05:00)太子港',
                    '(GMT-05:00)牙买加',
                    '(GMT-04:00)阿鲁巴',
                    '(GMT-04:00)安圭拉',
                    '(GMT-04:00)安提瓜',
                    '(GMT-04:00)巴巴多斯',
                    '(GMT-04:00)百慕大',
                    '(GMT-04:00)波多黎各',
                    '(GMT-04:00)博阿维斯塔',
                    '(GMT-04:00)大坎普市',
                    '(GMT-04:00)大西洋时间-哈利法克斯',
                    '(GMT-04:00)多米尼加',
                    '(GMT-04:00)格林纳达',
                    '(GMT-04:00)瓜德罗普',
                    '(GMT-04:00)圭亚那',
                    '(GMT-04:00)加拉加斯',
                    '(GMT-04:00)库拉索岛',
                    '(GMT-04:00)库亚巴',
                    '(GMT-04:00)拉巴斯',
                    '(GMT-04:00)马提尼克',
                    '(GMT-04:00)马瑙斯',
                    '(GMT-04:00)蒙特塞拉特',
                    '(GMT-04:00)帕默',
                    '(GMT-04:00)圣地亚哥',
                    '(GMT-04:00)圣多明戈',
                    '(GMT-04:00)圣基茨',
                    '(GMT-04:00)圣卢西亚',
                    '(GMT-04:00)圣托马斯',
                    '(GMT-04:00)圣文森特',
                    '(GMT-04:00)史丹利',
                    '(GMT-04:00)图勒',
                    '(GMT-04:00)托尔托拉',
                    '(GMT-04:00)韦柳港',
                    '(GMT-04:00)西班牙港',
                    '(GMT-04:00)亚松森',
                    '(GMT-03:30)纽芬兰时间-圣路易斯',
                    '(GMT-03:00)阿拉瓜伊纳',
                    '(GMT-03:00)贝伦',
                    '(GMT-03:00)布宜诺斯艾利斯',
                    '(GMT-03:00)福塔雷萨',
                    '(GMT-03:00)戈特霍布',
                    '(GMT-03:00)卡宴',
                    '(GMT-03:00)累西腓',
                    '(GMT-03:00)路德',
                    '(GMT-03:00)马塞约',
                    '(GMT-03:00)蒙得维的亚',
                    '(GMT-03:00)密克隆岛',
                    '(GMT-03:00)帕拉马里博',
                    '(GMT-03:00)萨尔瓦多',
                    '(GMT-03:00)圣保罗',
                    '(GMT-02:00)南乔治亚',
                    '(GMT-02:00)诺罗尼亚',
                    '(GMT-01:00)佛得角',
                    '(GMT-01:00)斯科列斯比桑德',
                    '(GMT-01:00)亚述尔群岛',
                    '(GMT+00:00)Atlantic/Faeroe',
                    '(GMT+00:00)阿比让',
                    '(GMT+00:00)阿克拉',
                    '(GMT+00:00)阿尤恩',
                    '(GMT+00:00)巴马科',
                    '(GMT+00:00)班珠尔',
                    '(GMT+00:00)比绍',
                    '(GMT+00:00)达喀尔',
                    '(GMT+00:00)都柏林',
                    '(GMT+00:00)弗里敦',
                    '(GMT+00:00)格陵兰',
                    '(GMT+00:00)加那利群岛',
                    '(GMT+00:00)卡萨布兰卡',
                    '(GMT+00:00)科纳克里',
                    '(GMT+00:00)雷克雅末克',
                    '(GMT+00:00)里斯本',
                    '(GMT+00:00)伦敦',
                    '(GMT+00:00)洛美',
                    '(GMT+00:00)蒙罗维亚',
                    '(GMT+00:00)努瓦克肖特',
                    '(GMT+00:00)圣多美',
                    '(GMT+00:00)圣赫勒拿',
                    '(GMT+00:00)瓦加杜古',
                    '(GMT+01:00)阿尔及尔',
                    '(GMT+01:00)阿姆斯特丹',
                    '(GMT+01:00)安道尔',
                    '(GMT+01:00)奥斯陆',
                    '(GMT+01:00)巴黎',
                    '(GMT+01:00)柏林',
                    '(GMT+01:00)班吉',
                    '(GMT+01:00)波多诺伏',
                    '(GMT+01:00)布达佩斯',
                    '(GMT+01:00)布拉柴维尔',
                    '(GMT+01:00)布鲁塞尔',
                    '(GMT+01:00)地拉那',
                    '(GMT+01:00)杜阿拉',
                    '(GMT+01:00)恩贾梅纳',
                    '(GMT+01:00)哥本哈根',
                    '(GMT+01:00)华沙',
                    '(GMT+01:00)金沙萨',
                    '(GMT+01:00)拉各斯',
                    '(GMT+01:00)利伯维尔',
                    '(GMT+01:00)卢森堡',
                    '(GMT+01:00)罗安达',
                    '(GMT+01:00)罗马',
                    '(GMT+01:00)马德里',
                    '(GMT+01:00)马耳他',
                    '(GMT+01:00)马拉博',
                    '(GMT+01:00)摩纳哥',
                    '(GMT+01:00)尼亚美',
                    '(GMT+01:00)斯德哥尔摩',
                    '(GMT+01:00)苏黎世',
                    '(GMT+01:00)突尼斯',
                    '(GMT+01:00)瓦杜兹',
                    '(GMT+01:00)维也纳',
                    '(GMT+01:00)温得和克',
                    '(GMT+01:00)休达',
                    '(GMT+01:00)直布罗陀',
                    '(GMT+01:00)中欧时间',
                    '(GMT+01:00)中欧时间',
                    '(GMT+02:00)安曼',
                    '(GMT+02:00)贝鲁特',
                    '(GMT+02:00)布加勒斯特',
                    '(GMT+02:00)布兰太尔',
                    '(GMT+02:00)布琼布拉',
                    '(GMT+02:00)大马士革',
                    '(GMT+02:00)的黎波里',
                    '(GMT+02:00)哈博罗内',
                    '(GMT+02:00)哈拉雷',
                    '(GMT+02:00)赫尔辛基',
                    '(GMT+02:00)基辅',
                    '(GMT+02:00)基加利',
                    '(GMT+02:00)基希讷乌',
                    '(GMT+02:00)加沙',
                    '(GMT+02:00)开罗',
                    '(GMT+02:00)里加',
                    '(GMT+02:00)卢本巴希',
                    '(GMT+02:00)卢萨卡',
                    '(GMT+02:00)马普托',
                    '(GMT+02:00)马塞卢',
                    '(GMT+02:00)明斯克',
                    '(GMT+02:00)莫斯科-01-加里宁格勒',
                    '(GMT+02:00)姆巴巴纳',
                    '(GMT+02:00)尼科西亚',
                    '(GMT+02:00)索非亚',
                    '(GMT+02:00)塔林',
                    '(GMT+02:00)特拉维夫',
                    '(GMT+02:00)维尔纽斯',
                    '(GMT+02:00)雅典',
                    '(GMT+02:00)伊斯坦布尔',
                    '(GMT+02:00)约翰内斯堡',
                    '(GMT+03:00)非洲/阿斯梅拉',
                    '(GMT+03:00)安塔那利佛',
                    '(GMT+03:00)巴格达',
                    '(GMT+03:00)巴林',
                    '(GMT+03:00)达累斯萨拉姆',
                    '(GMT+03:00)哈土穆',
                    '(GMT+03:00)吉布提',
                    '(GMT+03:00)卡塔尔',
                    '(GMT+03:00)坎帕拉',
                    '(GMT+03:00)科摩罗群岛',
                    '(GMT+03:00)科威特',
                    '(GMT+03:00)利雅得',
                    '(GMT+03:00)马约特岛',
                    '(GMT+03:00)摩加迪沙',
                    '(GMT+03:00)莫斯科+00',
                    '(GMT+03:00)奈洛比',
                    '(GMT+03:00)亚的斯亚贝巴',
                    '(GMT+03:00)亚丁',
                    '(GMT+03:00)昭和',
                    '(GMT+03:30)德黑兰',
                    '(GMT+04:00)巴库',
                    '(GMT+04:00)迪拜',
                    '(GMT+04:00)第比利斯',
                    '(GMT+04:00)留尼汪岛',
                    '(GMT+04:00)马埃',
                    '(GMT+04:00)马斯喀特',
                    '(GMT+04:00)毛里求斯',
                    '(GMT+04:00)莫斯科+01-萨马拉',
                    '(GMT+04:00)耶烈万',
                    '(GMT+04:30)喀布尔',
                    '(GMT+05:00)阿克套',
                    '(GMT+05:00)阿克托别',
                    '(GMT+05:00)阿什哈巴德',
                    '(GMT+05:00)杜尚别',
                    '(GMT+05:00)卡拉奇',
                    '(GMT+05:00)凯尔盖朗群岛',
                    '(GMT+05:00)马尔代夫',
                    '(GMT+05:00)莫斯科+02-叶卡捷琳堡',
                    '(GMT+05:00)塔什干',
                    '(GMT+05:30)科伦坡',
                    '(GMT+05:30)印度标准时间',
                    '(GMT+06:00)阿拉木图',
                    '(GMT+06:00)比什凯克',
                    '(GMT+06:00)查戈斯',
                    '(GMT+06:00)达卡',
                    '(GMT+06:00)莫森',
                    '(GMT+06:00)莫斯科+03-鄂木斯克、新西伯利亚',
                    '(GMT+06:00)廷布',
                    '(GMT+06:00)沃斯托克',
                    '(GMT+06:30)可可群岛',
                    '(GMT+06:30)仰光',
                    '(GMT+07:00)戴维斯',
                    '(GMT+07:00)河内',
                    '(GMT+07:00)金边',
                    '(GMT+07:00)科布多',
                    '(GMT+07:00)曼谷',
                    '(GMT+07:00)莫斯科+04-拉斯诺亚尔斯克',
                    '(GMT+07:00)圣诞岛',
                    '(GMT+07:00)万象',
                    '(GMT+07:00)雅加达',
                    '(GMT+08:00)澳门',
                    '(GMT+08:00)吉隆坡',
                    '(GMT+08:00)凯西',
                    '(GMT+08:00)马卡萨',
                    '(GMT+08:00)马尼拉',
                    '(GMT+08:00)莫斯科+05-伊尔库次克',
                    '(GMT+08:00)台北',
                    '(GMT+08:00)文莱',
                    '(GMT+08:00)乌兰巴托',
                    '(GMT+08:00)西部时间-佩思',
                    '(GMT+08:00)新加坡',
                    '(GMT+08:00)中国时间-北京',
                    '(GMT+08:00)中国香港',
                    '(GMT+09:00)查亚普拉',
                    '(GMT+09:00)帝力',
                    '(GMT+09:00)东京',
                    '(GMT+09:00)莫斯科+06-雅库茨克',
                    '(GMT+09:00)帕劳',
                    '(GMT+09:00)平壤',
                    '(GMT+09:00)乔巴山',
                    '(GMT+09:00)首尔',
                    '(GMT+09:30)中部央标准时间-阿德莱德',
                    '(GMT+09:30)中部央标准时间-达尔文',
                    '(GMT+10:00)迪蒙迪维尔',
                    '(GMT+10:00)东部时间-布里斯班',
                    '(GMT+10:00)东部时间-霍巴特',
                    '(GMT+10:00)东部时间-墨尔本、悉尼',
                    '(GMT+10:00)关岛',
                    '(GMT+10:00)莫尔兹比港',
                    '(GMT+10:00)莫斯科+07-南萨哈林斯克',
                    '(GMT+10:00)塞班岛',
                    '(GMT+10:00)特鲁克',
                    '(GMT+11:00)埃法特岛',
                    '(GMT+11:00)波纳佩岛',
                    '(GMT+11:00)瓜达康纳尔岛',
                    '(GMT+11:00)科斯瑞',
                    '(GMT+11:00)莫斯科+08-马加丹',
                    '(GMT+11:00)努美阿',
                    '(GMT+11:30)诺福克',
                    '(GMT+12:00)南极洲/麦克默多',
                    '(GMT+12:00)南极洲/South_Pole',
                    '(GMT+12:00)奥克兰',
                    '(GMT+12:00)富纳富提',
                    '(GMT+12:00)夸贾林岛',
                    '(GMT+12:00)马朱罗',
                    '(GMT+12:00)莫斯科+09-彼得罗巴甫洛夫斯克-堪察加',
                    '(GMT+12:00)塔拉瓦岛',
                    '(GMT+12:00)瓦利斯',
                    '(GMT+12:00)威克岛',
                    '(GMT+12:00)瑙鲁',
                    '(GMT+12:00)斐济',
                    '(GMT+13:00)东加塔布',
                    '(GMT+13:00)恩德贝里',
                    '(GMT+14:00)圣诞岛'

                ]
            }
        },
        created() {
            inventoryTypeInit().then(res => {
                console.log(res);
                this.inventoryTypeOptions = res.data.data;
            });
            unitOptionsInit().then(res => {
                console.log('unit', res);
                this.unitOptions = res.data.data;
            });
            ruleOptionsInit().then(res => {
                this.ruleOptions = res.data.data;
            })
            this.$nextTick(function () {
                getSettings().then(res => {
                    console.log("回显", res)
                    if (res.data.data) {
                        res.data.data.forEach(item => {
                            switch (item.code) {
                                case "unit":
                                    this.settings[0].value = item.value;
                                    break;
                                case "singleSystem":
                                    this.settings[1].value = item.value;
                                    break;
                                case "timeZone":
                                    this.settings[2].value = item.value;
                                    break;
                                case "faultRule":
                                    this.settings[3].value = item.value;
                                    break;
                                case "batchRule":
                                    this.settings[4].value = item.value;
                                    break;
                                case "specSheetRule":
                                    this.settings[5].value = item.value;
                                    break;
                                case "systemName":
                                    this.settings[6].value = item.value;
                                    break;
                                case "acceptIotData":
                                    this.settings[7].value = item.value;
                                    break;
                                case "defultTime":
                                    this.settings[8].value = item.value;
                                    break;
                                case "defultGroup":
                                    this.settings[9].value = item.value;
                                    break;
                                case "defultProduct":
                                    this.settings[10].value = item.value;
                                    break;
                                case "inventoryType":
                                    this.settings[11].value = Number.parseInt(item.value);
                                    break;
                                case "defultDoffing":
                                    this.settings[12].value = item.value;
                                    break;
                                case "priceCycle":
                                    this.settings[13].value = item.value;
                                    if (this.settings[13].value=='years'){
                                        this.dynamicCycleCountName="按年份周期数:"
                                    }else if (this.settings[13].value=='month') {
                                        this.dynamicCycleCountName="按月份周期数:"
                                    }else if (this.settings[13].value=='weeks') {
                                        this.dynamicCycleCountName="按周份周期数:"
                                    }
                                    break;
                                case "cycleCount":
                                    this.settings[14].value = item.value;
                                    this.cycleType=true
                                    break;
                                case "startTime":
                                    this.settings[15].value = item.value;
                                    this.startTimeType=true
                                    break;
                                case "fiscalStartTime":
                                    this.settings[16].value = item.value;
                                    break;
                                case "issueRule":
                                    this.settings[17].value = item.value;
                                    break;
                            }
                        })
                    }
                })
            })
        },
        methods: {
            changeGroup() {
                if (this.settings[9].value == 1) {
                    this.$message.warning("请到班组管理页面设置实际班组对应物联传输编号");
                } else if (this.settings[9].value == 2) {
                    this.$message.warning("请使用系统排班功能");
                }
            },
            save() {
                console.log('this.settings', JSON.stringify(this.settings));
                saveData(this.settings).then(res => {
                    console.log(res);
                    if (res.data.code == "0000") {
                        this.$message.success(res.data.msg);
                    }
                });
            },
            cycleChange(value) {
                this.cycleType = true;
                this.startTimeType = true;
                if (value=='years'){
                   this.dynamicCycleCountName="按年份周期数:"
                }else if (value=='month') {
                    this.dynamicCycleCountName="按月份周期数:"
                }else if (value=='weeks') {
                    this.dynamicCycleCountName="按周份周期数:"
                }
            },
        }
    }
</script>

<style>
    .el-collapse-item__header {
        font-size: 20px;
        font-weight: 700;
    }
</style>